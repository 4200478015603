export interface IValidate {
  /**
   * @description 验证链接格式
   * @param {string} path
   * @returns {boolean}
   */
  isExternal(path: string): boolean;

  /**
   * @description 验证邮箱格式
   * @param {string} email
   * @returns {boolean}
   */
  validEmail(email: string): boolean;

  /**
   * @description 验证手机号码格式
   * @param {string} phone
   * @returns {boolean}
   */
  validPhone(phone: string): boolean;

  /**
   * @description 验证身份证号码格式
   * @param idCard
   * @returns {boolean}
   */
  validIidCard(idCard: string): boolean;

  /**
   * @description 校验图片文件名
   * @param idCard
   * @returns {boolean}
   */
  validImageFileName(imgName: string): boolean;
}

class Validate implements IValidate {
  public isExternal(path: string): boolean {
    return /^(https?:|mailto:|tel:)/.test(path);
  }

  public validEmail(email: string): boolean {
    const reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(email);
  }

  public validPhone(phone: string): boolean {
    return /^1[3,4,5,6,7,8,9]\d{9}$/.test(phone);
  }

  public validIidCard(idCard: string): boolean {
    return /^[1-9][0-7]\d{4}((19\d{2}(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(19\d{2}(0[13578]|1[02])31)|(19\d{2}02(0[1-9]|1\d|2[0-8]))|(19([13579][26]|[2468][048]|0[48])0229))\d{3}(\d|X|x)?$/.test(
      idCard
    );
  }

  public validImageFileName(imgName: string): boolean {
    const reg = /(\.png|\.jpg|\.jpeg)$/i;
    return reg.test(imgName);
  }
}

const validateService = new Validate();
export default validateService;
