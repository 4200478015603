import type { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import type { App } from 'vue';
import routes from './routes';

export const whiteList = ['/login'];

const router: any = createRouter({
  history: createWebHistory(),
  routes: routes as unknown as RouteRecordRaw[],
  strict: true,
  scrollBehavior: () => ({ left: 0, top: 0 }),
});
router.onError((error: any) => {
  // 路由异步加载出现error：ChunkLoadError: Loading chunk about failed, 重新加载一次页面
  const pattern = /Loading chunk about failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});
export function resetRouter() {
  router.getRoutes().forEach((route: any) => {
    const { name } = route;
    if (name && !whiteList.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name);
    }
  });
}
export function setupRouter(App: App<Element>) {
  App.use(router);
}

export default router;
