import { pick } from 'lodash';
import UUID from 'uuid-js';

export const uuid4 = () => UUID.create().toString();

export const uuid1 = () => UUID.create(1).toString();

export function formatDate(date = new Date(), formatStr = 'yyyy/MM/dd hh:mm:ss') {
  if (typeof date !== 'object') {
    date = new Date(date);
  }
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(formatStr)) {
    formatStr = formatStr.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    );
  }
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(formatStr)) {
      formatStr = formatStr.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      );
    }
  }
  return formatStr;
}

/**
 * 通过枚举创建optons
 * @params enums 枚举值
 * @params keys {Array} 需要创建的options的key
 * @return {IOptions} options
 */

type IOptions = Array<{ label: string; value: any }>;

export const createOptionsByEnum = <T>(enums: T, keys: Array<keyof T>): IOptions => {
  const options: IOptions = [];
  const obj = pick(enums, keys);

  for (const [key, value] of Object.entries(obj)) {
    options.push({ label: key, value: value });
  }

  return options;
};

export function getSizeFromVideo(url: string): Promise<{ width: number; height: number }> {
  const video = document.createElement('video');
  video.src = url;
  video.style.display = 'none';
  return new Promise((resolve, reject) => {
    video.addEventListener('loadedmetadata', () => {
      document.body.removeChild(video);
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
      });
    });

    video.addEventListener('error', (err) => {
      reject(err);
    });

    document.body.appendChild(video);
  });
}
