import type { Router } from 'vue-router';
import NProgress from 'nprogress';
import { unref } from 'vue';
import { useViewStore } from '@/store/module/view';
export function createPageGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    if (to.name !== 'Redirect' && to.name !== 'four' && to.name !== 'login') {
      const viewStore = useViewStore();
      viewStore.addCachedView(unref(to));
    }
    NProgress.start();
    next();
  });
}
