import type { Router, RouteLocationNormalized } from 'vue-router';
import getPageTitle from '@/utils/get-page-title';
import NProgress from 'nprogress';

export function createPageTitleGuard(router: Router) {
  router.afterEach((to: RouteLocationNormalized) => {
    NProgress.done();
    document.title = getPageTitle(to.meta.title as string);
  });
}

