import { defineComponent, PropType } from 'vue';
import components from './components';

function generateComp(col: CommonForm.ColProp, values: Record<string, any>) {
  return components[col.type](col, values);
}

export default defineComponent({
  props: {
    col: {
      type: Object as PropType<CommonForm.ColProp>,
      default: () => null,
    },
    values: {
      type: Object as PropType<Record<string, any>>,
      default: () => null,
    },
  },
  setup(props: { col: CommonForm.ColProp, values: Record<string, any> }) {
    if (!props.col) return () => <span>invalid col attrs</span>;
    if (props.col.render) return () => props.col.render!(props.col, props.values);
    return () => generateComp(props.col, props.values);
  },
});
