import Layout from '@/layout/index.vue';
export default {
  path: '/dialogue',
  name: 'dialogue',
  component: Layout,
  redirect: '/dialogue/list',
  children: [
    {
      path: '/dialogue/list',
      name: 'Dialogue',
      component: () => import('@/views/dialogue/index.vue'),
      meta: { title: '账号', keepAlive: true, isSubMenu: true, show: true },
    },
    {
      path: '/dialogue/shortVideoPublicScene',
      name: 'dialogueVideoPublicScene',
      component: () => import('@/views/broadcast/views/publicScene/index.vue'),
      meta: { title: '公有数字人', isSubMenu: true, show: true, platformType: 3 },
    },
    {
      path: '/dialogue/shortVideoPublicTimbre',
      name: 'dialogueVideoPublicTimbre',
      component: () => import('@/views/broadcast/views/publicTimbre/index.vue'),
      meta: { title: '公有音色', isSubMenu: true, show: true, platformType: 3 },
    },
    {
      path: '/dialogue/equity-consumption',
      name: 'dialogueconsumption',
      component: () => import('@/views/dialogue/consumption/index.vue'),
      meta: { title: '权益消耗', keepAlive: false },
    },
    {
      path: '/dialogue/asset-allocation',
      name: 'dialogueassetallocation',
      component: () => import('@/views/dialogue/assetAllocation/index.vue'),
      meta: { title: '资产配置', keepAlive: false },
    },
    {
      path: '/dialogue/sort',
      name: 'dialogueSort',
      component: () => import('@/views/broadcast/views/sortView/index.vue'),
      meta: { title: '排序', keepAlive: false },
    },
  ],
  meta: {
    title: '对话平台',
    show: true,
    icon: 'duihualishi',
    isSubMenu: true, // 控制菜单是否为submenu
  },
};
