import defaultSetting from '@/settings/defaultSetting';

const title = defaultSetting.TITLE || '元分身';

const getPageTitle = (pageTitle: string) => {
  if (pageTitle) {
    return `${title}-${pageTitle}`;
  }
  return `${title}`;
};
export default getPageTitle;
