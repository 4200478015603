import { liveButton, emitter } from '../../mitt/index';

export const buttonList = [
  { id: 2, path: '/assets/human/list', title: '创建数智人' },
  { id: 4, path: '/platformPass/list', title: '创建账号' },
  { id: 5, path: '/platformPass/management', title: '创建应用' },
  { id: 6, path: '/platformPass/asset-allocation', title: '挂载数字人' },
  { id: 7, path: '/broadcast/asset-allocation', title: '挂载数字人' },
  { id: 8, path: '/account/asset-allocation', title: '挂载数字人' },
  { id: 9, path: '/broadcast/livePublicTimbre', title: '添加音色' },
  { id: 10, path: '/broadcast/livePublicScene', title: '添加数字人' },
  { id: 11, path: '/account/shortVideoPublicTimbre', title: '添加音色' },
  { id: 12, path: '/account/shortVideoPublicScene', title: '添加数字人' },
  { id: 13, path: '/assets/timbre/list', title: '创建音色' },
  { id: 14, path: '/accountManagement/enterprise', title: '创建账号' },
  { id: 15, path: '/accountManagement/personalAccount', title: '创建账号' },
  { id: 16, path: '/dialogue/shortVideoPublicTimbre', title: '添加音色' },
  { id: 17, path: '/dialogue/shortVideoPublicScene', title: '添加数字人' },
  { id: 18, path: '/dialogue/asset-allocation', title: '挂载数字人' },
];

export function showCreateAccount(e: any) {
  emitter.emit(liveButton, e);
}
