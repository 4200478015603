import Layout from '@/layout/index.vue';

export const orderRouter = {
  path: '/orderManagement',
  name: 'orderManagement',
  component: Layout,
  redirect: '/orderManagement/list',
  meta: {
    title: '订单管理',
    keepAlive: true,
    show: true,
    isSubMenu: true, // 控制菜单是否为submenu
    icon: 'a-zu2783',
  },
  children: [
    {
      path: '/orderManagement/list',
      name: 'OrderManagement',
      component: () => import('@/views/goods/orderManagement/index.vue'),
      meta: { title: '订单管理', keepAlive: true, isSubMenu: true, show: true },
    },
    {
      path: '/billmanagement/list',
      name: 'Billmanagement',
      component: () => import('@/views/goods/billmanagement/index.vue'),
      meta: { title: '账单管理', keepAlive: true, isSubMenu: true, show: true },
    },
    {
      path: '/paymentrecord/list',
      name: 'Paymentrecord',
      component: () => import('@/views/goods/paymentrecord/index.vue'),
      meta: { title: '账单支付记录', keepAlive: true, isSubMenu: true, show: true },
    },
    {
      path: '/paymentList',
      name: 'paymentList',
      component: () => import('@/views/goods/paymentList/index.vue'),
      meta: { title: '支付单', keepAlive: true, isSubMenu: true, show: true },
    },
    {
      path: '/paymentRequest',
      name: 'paymentRequest',
      component: () => import('@/views/goods/paymentRequest/index.vue'),
      meta: { title: '请求支付记录', keepAlive: true, isSubMenu: true, show: true },
    },
  ],
};
