import router from '@/router';

import { createPageGuard } from './pageGuard';

import { createPermissionGuard } from './permissionGuard';
import { createPageTitleGuard } from './pageTitleGuard';

createPageGuard(router);
createPermissionGuard(router);
createPageTitleGuard(router);
