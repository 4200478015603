import mitt from 'mitt';
export const emitter = mitt();

// 事件名称
export const liveButton = 'LIVEBUTTON';
// 取消全局弹框弹出
export const unbox = 'UNBOX';
// 数字人场景，音色
export const editRefresh = 'EDITREFRESH';
// 刷新应用音色
export const appliedTimbres = 'APPLIEDTIMBRE';
export const avatarRefresh = 'AVATARREFRESH';
