import { defineStore } from 'pinia';

interface IViewState {
  cachedViews: string[];
}
export const useViewStore = defineStore({
  id: 'view',
  state: (): IViewState => ({
    cachedViews: [],
  }),
  getters: {
    getCachedViews(): string[] {
      return this.cachedViews;
    },
  },
  actions: {
    addCachedView(view: any) {
      if (this.cachedViews.includes(view.name)) {
        return;
      }
      if (view.meta.keepAlive) {
        this.cachedViews.push(view.name);
      }
    },
  },
});
