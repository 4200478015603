class Storage {
  private name;
  constructor(name = '_') {
    this.name = name;
    this.init();
  }
  init() {
    Object.keys(localStorage).forEach((item) => {
      if (item.startsWith(this.name)) {
        this.hasItem(item.replace(this.name, ''));
      }
    });
  }
  setItem(key: string, value: any, time: any) {
    if (value === undefined) value = null;
    const _key = this.name + key;
    const _value: any = { value };
    time && (_value.time = time * 1000 + new Date().getTime());
    localStorage.setItem(_key, JSON.stringify(_value));
  }
  getItem(key: string) {
    if (this.hasItem(key)) {
      return JSON.parse(localStorage.getItem(this.name + key)!).value;
    }
    return null;
  }
  removeItem(key: string) {
    localStorage.removeItem(this.name + key);
  }
  clear() {
    Object.keys(localStorage).forEach((item) => {
      if (item.startsWith(this.name)) {
        localStorage.removeItem(item);
      }
    });
  }
  length() {
    return Object.keys(localStorage).length;
  }
  hasItem(key: string) {
    const _key = this.name + key;
    if (localStorage.getItem(_key)) {
      const _time = JSON.parse(localStorage.getItem(_key)!).time;
      if (new Date().getTime() > _time) {
        localStorage.removeItem(_key);
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
}
const storage = new Storage();
export default storage;
