import { defineStore } from 'pinia';
import { ComponentInternalInstance } from 'vue';

export const useFormStore = defineStore('formStore', {
  state: (): {
    formMap: Map<string, ComponentInternalInstance>;
  } => ({
    formMap: new Map(),
  }),
  actions: {
    set(id: string, val: ComponentInternalInstance) {
      if (this.formMap.has(id)) {
        throw `${id} already exist!`;
      }
      this.formMap.set(id, val);
    },
    delete(id: string) {
      this.formMap.delete(id);
    },
    get(id: string) {
      return this.formMap.get(id);
    },
    validateForm(id: string) {
      // @ts-ignore
      return this.getFormIns(id)?.validate();
    },
    getFormIns(id: string) {
      return this.get(id)?.refs.form;
    },
    getFormValues(id: string) {
      return this.get(id)?.exposed?.getValues();
    },
    initFormValues(id: string, val: Record<string, any>) {
      this.get(id)?.exposed?.initValues(val);
    },
    resetFormValues(id: string) {
      this.get(id)?.exposed?.resetValues();
    },
    clearFormValidate(id: string, name?: string | string[]) {
      // @ts-ignore
      this.getFormIns(id)?.clearValidate(name);
    },
  },
});

