import { defineStore } from 'pinia';
import { UserApi } from '@/api/user';

export const useGlobalConfigStore = defineStore('globalConfigStore', {
  state: () => ({
    brandList: [{ id: 0, name: '全部品牌' }],
  }),
  actions: {
    async getBrandList() {
      const res = await UserApi.getBrandList();
      this.brandList.push(...res?.data);
    },
  },
});
