import { uuid4 } from '@/utils/tools';
import { RowProps, ColProps } from 'element-plus';

// 重组配置
export function buildConfig(column: CommonForm.FormConfig): CommonForm.BuildedConfig {
  try {
    const rows: Array<{ cols: Array<CommonForm.ColProp> }> = [];
    column.children.forEach((item) => {
      item.id = uuid4();
      if (rows[item.row]) {
        rows[item.row].cols.push(item);
      } else {
        rows[item.row] = { cols: [item] };
      }
    });
    return {
      ...column,
      rows: rows.filter((item) => item).map((item) => ({ ...item, id: uuid4() })),
      id: uuid4(),
    };
  } catch (error) {
    window.console.log(error);
    return null as any;
  }
}

// 提取表单值
export function buildValues(column: CommonForm.FormConfig) {
  try {
    const values: Record<string, any> = {};
    const singleKey = [
      'render',
      'formInput',
      'formDate',
      'formRadio',
      'formSelect',
      'formInputClick',
      'formCascader',
      'formAutograph',
    ];

    column.children.forEach((item) => {
      const { type, key, defaultValue } = item;
      // @ts-ignore
      if (singleKey.includes(type)) {
        values[key] = typeof defaultValue !== 'undefined' ? defaultValue : '';
      }
    });
    return values;
  } catch (error) {
    window.console.log(error);
    return {};
  }
}

// 合并参数
const defaultRowProps = {
  gutter: 20,
};
const defaultColProps = {
  span: 4,
};
export const buildRowProps = (p?: Partial<RowProps>) => Object.assign(defaultRowProps, p || {});
export const buildColProps = (p?: Partial<ColProps>) => Object.assign(defaultColProps, p || {});
