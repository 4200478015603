import Layout from '@/layout/index.vue';
export default {
  path: '/online',
  name: 'online',
  component: Layout,
  redirect: '/online',
  meta: {
    title: '任务管理',
    show: true,
    isSubMenu: true, // 控制菜单是否为submenu
    icon: 'a-zu3660',
  },
  children: [
    // 数字人
    {
      path: '/online/train',
      name: 'OnlineTrain',
      component: () => import('@/views/online/train/index.vue'),
      meta: {
        title: '数智人Online训练',
        keepAlive: true,
        show: true,
        isIcon: true,
      },
    },
    {
      path: '/online/train/auth/:id/:status',
      name: 'OnlineTrainAuth',
      component: () => import('@/views/online/train/auth.vue'),
      meta: { title: '授权审核', keepAlive: false },
    },
    {
      path: '/online/train/effect/:id',
      name: 'OnlineTrainEffect',
      component: () => import('@/views/online/train/effect.vue'),
      meta: { title: '效果审核', keepAlive: false },
    },
    {
      path: '/online/train/reject/:id',
      name: 'OnlineTrainReject',
      component: () => import('@/views/online/train/reject.vue'),
      meta: { title: '驳回', keepAlive: false },
    },
    // 音色
    {
      path: '/onlineTimbre/timbre',
      name: 'OnlineTimbre',
      component: () => import('@/views/onlineTimbre/timbre/index.vue'),
      meta: {
        title: '音色Online训练',
        keepAlive: true,
        show: true,
        isIcon: true,
      },
    },
    {
      path: '/onlineTimbre/timbre/:id/:status',
      name: 'OnlineTimbreAuth',
      component: () => import('@/views/onlineTimbre/timbre/auth.vue'),
      meta: { title: '授权审核', keepAlive: false },
    },
    {
      path: '/onlineTimbre/timbre/effect/:id',
      name: 'OnlineTimbreEffect',
      component: () => import('@/views/onlineTimbre/timbre/effect.vue'),
      meta: { title: '效果审核', keepAlive: false },
    },
    {
      path: '/onlineTimbre/timbre/reject/:id',
      name: 'OnlineTimbreReject',
      component: () => import('@/views/onlineTimbre/timbre/reject.vue'),
      meta: { title: '驳回', keepAlive: false },
    },
    // pro音色训练
    {
      path: '/pro/timbre',
      name: 'ProTimbre',
      component: () => import('@/views/pro/timbre/index.vue'),
      meta: {
        title: '音色Pro训练',
        keepAlive: true,
        show: true,
        isIcon: true,
      },
    },
    {
      path: '/pro/human',
      name: 'ProHuman',
      component: () => import('@/views/pro/human/index.vue'),
      meta: {
        title: '数字人Pro训练',
        keepAlive: true,
        show: true,
        isIcon: true,
      },
    },
  ],
};
