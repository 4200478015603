import { createApp } from 'vue';
import App from './App.vue';
import { setupStore } from './store';
import { setupRouter } from './router';
import '@/router/guard';
// import 'element-plus/es/components/message/style/css';
// import 'element-plus/es/components/message-box/style/css';
import '@/assets/font/font.css';
import '@/assets/iconfont/iconfont';
import '@/styles/index.scss';
import SvgIcon from '@/components/SvgIcon/index.vue';
import CommonDialog from '@/components/CommonDialog/index.vue';
import CommonSearch from '@/components/CommonSearch/index.vue';
import CommonForm from '@/components/CommonForm/index.vue';
import { ExIcon, ExTable } from '@ms-mutual/ui';
import VueCropper from 'vue-cropper';
import 'vue-cropper/dist/index.css';
import '@ms-mutual/ui/dist/index.min.css';

const req = require.context('./assets/icons', true, /\.svg$/);

const requireAll = (requireContext: any) => requireContext.keys().map(requireContext);
requireAll(req);
const app = createApp(App);
app.use(VueCropper);
app.component('SvgIcon', SvgIcon);
app.component('ExIcon', ExIcon);
app.component('ExTable', ExTable);
app.component('CommonDialog', CommonDialog);
app.component('CommonSearch', CommonSearch);
app.component('CommonForm', CommonForm);

setupRouter(app);
setupStore(app);

app.mount('#app');
