import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/index.vue';
import { reactive } from 'vue';
// import orderManagement from './modules/goodsRouter';
import onlieRouter from './modules/onlieRouter';
import { orderRouter } from './modules/goodsRouter';
import dialogueRouter from './modules/dialogueRouter';

// 运营管理路由
const operateRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'accountManagement',
    component: Layout,
    redirect: '/accountManagement/enterprise',
    children: [
      {
        path: '/accountManagement/enterprise',
        name: 'enterprise',
        component: () => import('../views/accountManagement/enterprise.vue'),
        meta: { title: '企业管理', keepAlive: true, isSubMenu: true, show: true },
      },
      {
        path: '/accountManagement/enterprise/detail',
        name: 'enterpriseDetail',
        component: () => import('../views/accountManagement/components/enterpriseDetail.vue'),
        meta: { title: '企业管理', isSubMenu: true, show: false },
      },
      {
        path: '/accountManagement/personalAccount',
        name: 'personalAccount',
        component: () => import('../views/accountManagement/personalAccount.vue'),
        meta: { title: '个人账号管理', keepAlive: true, isSubMenu: true, show: true },
      },
      {
        path: '/accountManagement/userEnterprise/detail',
        name: 'userEnterpriseDetail',
        component: () => import('../views/accountManagement/components/accountDetail.vue'),
        meta: { title: '个人账号管理', isSubMenu: true, show: false },
      },
    ],
    meta: {
      title: '账号管理',
      show: true,
      icon: 'yunyingduanzhanghaoguanli',
      isSubMenu: true, // 控制菜单是否为submenu
      mainTitle: '运营管理',
    },
  },
  {
    path: '/',
    name: 'home',
    component: Layout,
    redirect: '/account/list',
    children: [
      {
        path: '/account/list',
        name: 'Account',
        component: () => import('../views/account/index.vue'),
        meta: { title: '账号', keepAlive: true, isSubMenu: true, show: true },
      },
      {
        path: '/account/shortVideoPublicScene',
        name: 'shortVideoPublicScene',
        component: () => import('../views/broadcast/views/publicScene/index.vue'),
        meta: { title: '公有数字人', isSubMenu: true, show: true, platformType: 1 },
      },
      {
        path: '/account/shortVideoPublicTimbre',
        name: 'shortVideoPublicTimbre',
        component: () => import('../views/broadcast/views/publicTimbre/index.vue'),
        meta: { title: '公有音色', isSubMenu: true, show: true, platformType: 1 },
      },
      {
        path: '/account/equity-consumption',
        name: 'equityconsumption',
        component: () => import('../views/account/consumption/index.vue'),
        meta: { title: '权益消耗', keepAlive: false },
      },
      {
        path: '/account/asset-allocation',
        name: 'assetallocation',
        component: () => import('../views/account/assetAllocation/index.vue'),
        meta: { title: '资产配置', keepAlive: false },
      },
      {
        path: '/account/sort',
        name: 'accountSort',
        component: () => import('../views/broadcast/views/sortView/index.vue'),
        meta: { title: '排序', keepAlive: false },
      },
    ],
    meta: {
      title: '视频制作平台',
      show: true,
      icon: 'tubiao',
      isSubMenu: true, // 控制菜单是否为submenu
    },
  },
  dialogueRouter,
  {
    path: '/broadcast',
    name: 'broadcast',
    component: Layout,
    redirect: '/broadcast/list',
    children: [
      {
        path: '/broadcast/list',
        name: 'Broadcast',
        component: () => import('../views/broadcast/index.vue'),
        meta: {
          title: '账号',
          keepAlive: true,
          isSubMenu: true, // 控制菜单是否为submenu
          show: true,
        },
      },
      {
        path: '/broadcast/livePublicScene',
        name: 'livePublicScene',
        component: () => import('../views/broadcast/views/publicScene/index.vue'),
        meta: { title: '公有数字人', isSubMenu: true, show: true, platformType: 1 },
      },
      {
        path: '/broadcast/livePublicTimbre',
        name: 'livePublicTimbre',
        component: () => import('../views/broadcast/views/publicTimbre/index.vue'),
        meta: { title: '公有音色', isSubMenu: true, show: true, platformType: 1 },
      },
      {
        path: '/broadcast/dataBoard',
        name: 'dataBoard',
        component: () => import('../views/broadcast/views/dataBoard/index.vue'),
        meta: { title: '数据看板', keepAlive: true, isSubMenu: true, show: true, platformType: 1 },
      },
      {
        path: '/broadcast/dataBoard/projectDetails',
        name: 'projectDetails',
        component: () => import('../views/broadcast/views/dataBoard/projectDetails/index.vue'),
        meta: { title: '产品个数/话术条数', keepAlive: false },
      },
      {
        path: '/broadcast/interactiveDetails',
        name: 'interactiveDetails',
        component: () =>
          import('../views/broadcast/views/dataBoard/usageInformation/interactiveDetails.vue'),
        meta: { title: '互动详情', show: false, platformType: 1 },
      },
      {
        path: '/broadcast/interactiveBroadcasts',
        name: 'interactiveBroadcasts',
        component: () =>
          import('../views/broadcast/views/dataBoard/usageInformation/interactiveBroadcasts.vue'),
        meta: { title: '开播次数/互动次数', show: false, platformType: 1 },
      },
      {
        path: '/broadcast/sort',
        name: 'BroadcastSort',
        component: () => import('../views/broadcast/views/sortView/index.vue'),
        meta: { title: '排序', keepAlive: false },
      },
      {
        path: '/broadcast/equity',
        name: 'equity',
        component: () => import('../views/broadcast/equity/index.vue'),
        meta: { title: '权益', keepAlive: false },
      },
      {
        path: '/broadcast/asset-allocation',
        name: 'property',
        component: () => import('../views/broadcast/property/index.vue'),
        meta: { title: '资产配置', keepAlive: false },
      },
    ],
    meta: {
      title: '直播平台',
      show: true,
      isSubMenu: true, // 控制菜单是否为submenu
      icon: 'yunyingduanzhibozhanghao',
    },
  },
  {
    path: '/platformPass',
    name: 'platformPass',
    component: Layout,
    redirect: '/platformPass/list',
    children: [
      {
        path: '/platformPass/list',
        name: 'PlatformPass',
        component: () => import('../views/platformPass/index.vue'),
        meta: { title: 'Paas平台', keepAlive: true, singleMenu: true /* 重定向路由需要加上*/ },
      },
      {
        path: '/platformPass/equity',
        name: 'platformPassEquity',
        component: () => import('../views/platformPass/equity/index.vue'),
        meta: { title: '权益', keepAlive: false },
      },
      {
        path: '/platformPass/asset-allocation',
        name: 'platformProperty',
        component: () => import('../views/platformPass/property/index.vue'),
        meta: { title: '资产配置', keepAlive: false },
      },
      {
        path: '/platformPass/management',
        name: 'platformManagement',
        component: () => import('../views/platformPass/management/index.vue'),
        meta: { title: '管理应用', keepAlive: false },
      },
    ],
    meta: {
      title: 'Paas平台',
      show: true,
      icon: 'Paaspingtaizhanghao',
      singleMenu: true,
    },
  },
  {
    path: '/userApp',
    name: 'userApp',
    component: Layout,
    redirect: '/userApp/list',
    children: [
      {
        path: '/userApp/list',
        name: 'UserApp',
        component: () => import('../views/userApp/index.vue'),
        meta: { title: '躺赚播', keepAlive: true, singleMenu: true /* 重定向路由需要加上*/ },
      },
      {
        path: '/userApp/equity',
        name: 'userAppEquity',
        component: () => import('../views/userApp/equity/index.vue'),
        meta: { title: '权益', keepAlive: false },
      },
    ],
    meta: {
      title: '躺赚播',
      show: true,
      icon: 'houtaicaidan-tangzhuanbo',
      singleMenu: true,
    },
  },
  {
    path: '/assets',
    name: 'assets',
    component: Layout,
    redirect: '/assets',
    meta: {
      title: '资产管理',
      show: true,
      isSubMenu: true, // 控制菜单是否为submenu
      icon: 'yunyingduanzichanguanli',
    },
    children: [
      {
        path: '/assets/human/list',
        name: 'Human',
        component: () => import('../views/assets/human/index.vue'),
        meta: {
          title: '数智人',
          keepAlive: true,
          isSubMenu: true, // 控制菜单是否为submenu
          show: true,
        },
      },
      {
        path: '/assets/editor/digital',
        name: 'editorDigital',
        component: () => import('../views/assets/human/edit/index.vue'),
        meta: reactive({
          title: '数智人详情',
          keepAlive: false,
        }),
      },
      {
        path: '/assets/timbre/list',
        name: 'Timbre',
        component: () => import('../views/assets/timbre/index.vue'),
        meta: {
          title: '音色',
          keepAlive: false,
          isSubMenu: true, // 控制菜单是否为submenu
          show: true,
        },
      },
    ],
  },

  {
    path: '/videoPlayback',
    name: 'videoPlayback',
    component: () => import('../views/assets/human/component/cropperVideo.vue'),
    meta: {
      title: '视频播放',
      show: false,
    },
  },

  onlieRouter,
  orderRouter,
];

const basicRoutes: Array<RouteRecordRaw> = [
  {
    path: '/user/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
    meta: {
      title: '运营管理',
      show: false,
    },
  },
  {
    path: '/redirect/:path(.*)',
    name: 'Redirect',
    component: () => import('../views/redirect/index.vue'),
    meta: {
      title: '重定向页面',
      show: false,
    },
  },
  {
    path: '/:path(.*)',
    name: 'four',
    component: () => import('../views/exception/error/index.vue'),
    meta: {
      show: false,
    },
  },
];
// , ...agentRoutes
const routes = [...operateRoutes, ...basicRoutes];
export default routes;
